<script lang="ts" setup>
import type { GameTask } from '~/services'

const tasksStore = useTasksStore()
const tasksHook = useTasks()

const allTasks = computed(() => {
  const notEmptyTasks: { [key: string]: GameTask[] } = {}
  for (const key in tasksStore.preparedTasks) {
    if (tasksStore.preparedTasks[key]!.length !== 0) {
      notEmptyTasks[key] = tasksStore.preparedTasks[key]!
    }
  }
  return notEmptyTasks
})

const tasksKeys = computed(() =>
  Object.keys(allTasks.value)
    .filter(k => !['card'].includes(k))
    .sort((a, b) => {
      const order = ['social', 'holders_club', 'other'] // Заданный порядок
      const indexA = order.indexOf(a)
      const indexB = order.indexOf(b)

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB
      }

      if (indexA !== -1) {
        return -1
      }
      if (indexB !== -1) {
        return 1
      }

      return b.localeCompare(a)
    }),
)

const cardsTasks = computed(() => {
  return allTasks.value.card
})

function sortTasks(tasks?: GameTask[]) {
  return tasks?.sort((a, b) => tasksHook.sortTasks(a, b))
}

function completedTasksCount(tasks?: GameTask[]) {
  const count = tasks?.filter(t => tasksStore.state.claimedTasks.find(c => c.taskId === t.id))?.length
  return count ?? 0
}

function normalizeTitle(title: string) {
  return title.replace('_', ' ')
}
</script>

<template>
  <div class="tasks">
    <div class="tasks-title">
      Earn
    </div>

    <div class="tasks-desc">
      Complete simple tasks and earn additional JP for your activity. The more interactions, the more rewards you get.
    </div>

    <div v-if="cardsTasks" class="tasks-cards">
      <card-task v-for="task in sortTasks(cardsTasks)" :key="task.id" :task="task" />
    </div>

    <div class="tasks-container">
      <q-expansion-item
        v-for="taskKey in tasksKeys"
        :key="taskKey"
        header-class="tasks-container__header"
        :label="taskKey"
        default-opened
      >
        <template #header>
          <div class="title">
            {{ normalizeTitle(taskKey) }}
          </div>
          <div class="progress">
            {{ completedTasksCount(allTasks[taskKey]) }}/{{ allTasks[taskKey]?.length }}
          </div>
        </template>
        <default-task v-for="task in sortTasks(allTasks[taskKey])" :key="task.id" :task="task" />
      </q-expansion-item>
    </div>

    <task-notify />
  </div>
</template>

<style lang="scss">
.tasks {
  height: 100vh;
  background-color: $game-bg;
  color: #fff;
  padding: 17px 0 0;
  overflow: auto;

  &-title {
    font-family: $font-chango;
    font-size: 26px;
    font-weight: 400;
    padding: 0 0 16px 28px;
  }

  &-desc {
    color: $gray-4;
    font-family: $font-secondary;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding: 0 28px 15px;
  }

  &-cards {
    width: 100%;
    height: 200px;
    display: flex;
    gap: 15px;
    padding: 0 28px;
    overflow: auto;

    &__item {
      min-width: 322px;
      width: 322px;
      height: 200px;
      border-radius: 12px;
      background: $primary;
      padding: 15px 12px;
      display: flex;
      flex-direction: column;

      .item-reward {
        color: #fff;
        font-family: $font-secondary;
        font-size: 23px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;

        i {
          display: flex;
          align-items: center;

          svg {
            width: 32px;
            height: 32px;

            path:first-of-type {
              fill: $gray-4;
            }
          }
        }
      }

      .item-info {
        color: #fff;
        font-family: $font-secondary;
        font-size: 17px;
        font-weight: 600;
        line-height: 20px;
        padding-top: 35px;
      }

      .item-action {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 18px;
        color: #fff;
        font-family: $font-secondary;
        font-size: 20px;
        font-weight: 400;
        font-variant-numeric: tabular-nums;
        padding-top: 8px;
        margin-top: auto;

        .q-btn {
          border-radius: $border-radius;
          background: $gray;
          text-transform: initial;
          font-family: $font-secondary;
          font-size: 15px;
          font-weight: 500;
          width: 78px;
          height: 38px;
          padding: 0;
        }
      }
    }
  }

  &-container {
    position: relative;
    padding-bottom: 122px;
    height: auto;
    overflow: hidden;
    .q-item {
      background-color: #213138;
      z-index: 0;
    }

    &__header {
      text-transform: capitalize;
      color: $gray-3;
      font-family: $font-secondary;
      font-size: 17px;
      font-weight: 700;
      line-height: normal;
      padding: 0 0 0 28px;

      .title {
        display: flex;
        align-items: center;
      }

      .progress {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-right: 10px;
        color: $gray-4;
        font-family: $font-secondary;
        font-size: 17px;
        font-weight: 700;
        line-height: normal;
      }
    }

    .task-default {
      position: relative;
      padding: 15px 18px 15px 28px;
      display: flex;
      align-items: center;
      gap: 15px;

      &::after {
        content: '';
        width: 90%;
        height: 1px;
        background-color: $primary;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      img {
        width: 26px;
        height: auto;
        object-fit: contain;
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: #fff;
        font-family: $font-secondary;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        padding-right: 18px;

        .reward {
          color: $gray-3;
          font-size: 15px;
          font-weight: 500;
          line-height: 21px;
          display: flex;
          align-items: center;
          gap: 4px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .q-btn {
        border-radius: $border-radius;
        background: $gray;
        margin-left: auto;
        min-height: 0;
        height: 36px;
        min-width: 78px;
        width: 78px;
        padding: 0;
      }
    }
  }

  .claimed-disable {
    opacity: 0.5;
    .q-btn {
      background-color: $gray !important;
      color: #fff !important;
    }
  }
}
</style>
