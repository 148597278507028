<script lang="ts" setup>
import { matArrowBackIos } from '@quasar/extras/material-icons'
import { useDebounceFn } from '@vueuse/core'
import barrelImage from '~/assets/img/game/barrel.png'
import boostBtnImage from '~/assets/img/game/boost-btn.svg?raw'
import overheatedIcon from '~/assets/img/icon/overheatet.png'
import { COOLING_DURATION } from '~/config'
import { dynamicThrottle, isClickInsideCircle } from '~/utils'

const emit = defineEmits(['gameHandler', 'tabHandler'])

const gameStore = useGameStore()

const { updateTowerState, updateGameUserData } = useGame()

const platform = computed(() => gameStore.useWebApp?.platform)

const barrel = computed(() => gameStore.gameConfig?.tower?.barrel ?? 42)

const clicks = ref(0)
const isClicks = ref(false)
const stopTower = ref(false)

const actionPanel = ref()
const actionBtn = ref()
const activeAnimation = ref(false)

const barrels = computed(() => gameStore.state.gameUserData ? Number(gameStore.state.gameUserData?.barrels) : 0)
const pointsWithOil = computed(() => (gameStore.state.farmGallons - gameStore.state.savedGallons) + barrels.value)

let heatLevelInterval

const debouncedFn = useDebounceFn(async () => {
  heatLevelInterval = setInterval(() => {
    coolingTowerCalculater()
  }, COOLING_DURATION)
  await Promise.allSettled([
    updateGameUserData(),
    updateTowerState(),
  ])
  clicks.value = 0
}, 1000)

const animationDebFb = useDebounceFn(async () => {
  isClicks.value = false
}, 300)

async function handleDown() {
  animationDebFb()
  await debouncedFn()
}

const enduranceThrottle = computed(() => {
  if (gameStore.towerState.heatLevel < 30) {
    return 0.2
  } else if (gameStore.towerState.heatLevel >= 30 && gameStore.towerState.heatLevel < 60) {
    return 0.2 + (0.3 * (gameStore.towerState.heatLevel - 30) / 30)
  } else if (gameStore.towerState.heatLevel >= 60) {
    return 0.5 + (0.5 * ((gameStore.towerState.heatLevel - 60) / 40) ** 2)
  }
  return 0
})

const calculateProgressPercentage = computed(() => {
  const value = Number(pointsWithOil.value ?? 0)
  const fullCycles = Math.floor(value / barrel.value)
  const remainder = value % barrel.value
  const percentage = (remainder / barrel.value) * 100

  return {
    fullCycles,
    percentage,
  }
})

function throttledAction() {
  activeAnimation.value = true
  setTimeout(() => activeAnimation.value = false, enduranceThrottle.value * 900)
  gameStore.state.farmGallons += gameStore.gameConfig?.tower?.gallonsPerClick ?? 0
  gameStore.towerState.heatLevel += gameStore.gameConfig?.tower?.towerHeatPerClick ?? 0
  addCoin()
}

let throttledUpdateGameData = dynamicThrottle(throttledAction, enduranceThrottle.value * 1000)

watch(enduranceThrottle, (newValue) => {
  throttledUpdateGameData = dynamicThrottle(throttledAction, newValue * 1000)
})

async function handleClick(e) {
  if (!isClickInsideCircle(e, actionBtn.value)) {
    return
  }
  // if (platform.value === 'android') {
  //   navigator.vibrate(30)
  // }
  if (platform.value === 'ios') {
  // @ts-expect-error...
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light')
  }
  gameStore.towerState.lastClickTime = Date.now()
  clicks.value++
  isClicks.value = true
  if (gameStore.towerState.heatLevel >= 100) {
    return
  }
  throttledUpdateGameData()
  clearInterval(heatLevelInterval)
}

function coolingTowerCalculater() {
  gameStore.calculatedTowerData(gameStore.towerState)
  if (gameStore.towerState.heatLevel === 0) {
    clearInterval(heatLevelInterval)
  }
}

function addCoin() {
  const coin = document.createElement('div')
  coin.textContent = `+${gameStore.gameConfig?.tower?.gallonsPerClick ?? 0}`
  coin.classList.add('coin')

  actionPanel.value.appendChild(coin)

  setTimeout(() => {
    actionPanel.value.removeChild(coin)
  }, 1000)
}

const btnAnimation = computed(() => {
  return `btnAnimation ${enduranceThrottle.value}s forwards`
})

const lightingColor = computed(() => {
  const { heatLevel } = gameStore.towerState
  if (heatLevel >= 42 && heatLevel < 74) {
    return '#f97800'
  }
  if (heatLevel > 74) {
    return '#e21615'
  }
  return '#08f2d0'
})

watch(() => gameStore.towerState, (d) => {
  if (d) {
    if (d.heatLevel) {
      coolingTowerCalculater()

      heatLevelInterval = setInterval(() => {
        coolingTowerCalculater()
      }, COOLING_DURATION)
    }
  }
}, { immediate: true })

onUnmounted(() => {
  clearInterval(heatLevelInterval)
})
</script>

<template>
  <div class="pumpjack-game">
    <q-btn unelevated class="back-btn" @click="emit('gameHandler')">
      <q-icon :name="matArrowBackIos" />
      Home
    </q-btn>

    <div class="game-view">
      <div v-if="gameStore.towerState.heatLevel >= 100" class="heat-bg">
        <div class="heat-bg__overheated">
          <img :src="overheatedIcon" alt="overheated icon">
          Overheated
          <img :src="overheatedIcon" alt="overheated icon">
        </div>
      </div>
      <game-farming-progress @stop-tower="(e) => stopTower = e" />
      <game-tower :clicks="clicks" :stop-tower="stopTower" />
    </div>

    <div class="game-stats">
      <div class="game-stats__details">
        <div class="barrel-progress">
          <div class="barrel-progress-bar" />
        </div>
        <div class="barrel-right">
          <img :src="barrelImage" class="barrel-image">
          <span>{{ calculateProgressPercentage.fullCycles }}</span>
        </div>
        <q-btn unelevated class="sell-btn" @click="emit('tabHandler', 'exchange')">
          Sell
        </q-btn>
      </div>
    </div>

    <q-btn class="booted-btn" unelevated @click="emit('gameHandler')">
      <i-app-energy color="#ffcb25" /> Boost
    </q-btn>

    <div class="game-action">
      <div ref="actionPanel" class="game-action__panel">
        <temperature-sensor :endurance="gameStore.towerState.heatLevel" />
        <!-- <click-count-sensor :clicks="clicks" /> -->

        <div
          ref="actionBtn"
          class="game-action__btn"
          :class="{
            'active-boosted': isClicks,
            'active-animation': activeAnimation,
            'action-overheated': gameStore.towerState.heatLevel >= 100 }"
          unelevated
          @mousedown="handleClick" @mouseup="handleDown"
        >
          <i
            v-html="boostBtnImage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.pumpjack-game {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $game-bg;

  .back-btn {
    position: fixed;
    left: 14px;
    top: -60px;
    border-radius: 8.533px;
    background: rgba(0, 0, 0, 0.22);
    padding: 0;
    color: #fff;
    height: 38px;
    width: auto;
    z-index: 111;
    text-transform: initial;
    font-family: $font-secondary;
    font-size: 13.057px;
    font-weight: 600;
    line-height: normal;
    padding: 0 19px;
    transition: 0.5s;

    .q-icon {
      font-size: 14px;
    }
  }

  .booted-btn {
    position: fixed;
    bottom: 134px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $gray;
    color: #fff;
    text-transform: initial;
    height: 62px;
    width: 94%;
    border-radius: 9px;
    font-family: $font-secondary;
    font-size: 15.5px;
    font-weight: 700;
    line-height: 30px;
    z-index: 111;

    svg {
      width: 17px;
      height: 26px;
      margin-right: 12px;
    }
  }

  .game {
    &-field {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
    &-view {
      min-height: 306px;
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background:
        url(~/assets/img/game/clouds.svg) no-repeat,
        linear-gradient(32deg, rgba(125, 209, 254, 1) 0%, rgba(12, 136, 208, 1) 100%);
      background-position: top;
      background-size: contain;
      overflow: hidden;

      &:before {
        content: '';
        width: 100%;
        height: 156px;
        position: absolute;
        bottom: -2px;
        z-index: 1;
        background: url(~/assets/img/game/field.svg) no-repeat bottom;
        background-size: 100%;
      }

      .oil-rick-animation {
        position: absolute;
        bottom: -94px;
        z-index: 2;

        svg {
          path {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }

      .heat-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;

        &__overheated {
          background-color: $negative;
          color: #fff;
          width: 240px;
          height: 46px;
          padding: 8px 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: $border-radius;
          font-family: $font-secondary;
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 2px;
          text-transform: uppercase;

          img {
            width: 18px;
            height: 18px;
            object-fit: contain;
          }
        }
      }
    }

    &-stats {
      min-height: 48px;
      height: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__info {
        color: $gray-4;
        font-weight: 500;
        line-height: normal;
        padding: 0 20px;

        span {
          color: $gray-3;
        }
      }

      &__details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        color: #fff;

        .barrel-right {
          position: relative;
          display: flex;
          gap: 4px;
          margin-left: 17px;

          span {
            font-family: $font-secondary;
            color: #fff;
            font-size: 21px;
            font-weight: 600;
            line-height: normal;
            font-variant-numeric: tabular-nums;
          }
        }

        .barrel-image {
          height: 26px;
          width: auto;
          object-fit: contain;
        }

        .barrel-progress {
          position: relative;
          flex: 1;
          border-radius: 60px;
          opacity: 0.83;
          background: $gray-3;
          height: 18px;
          overflow: hidden;
          border: 3px solid $gray-3;

          .barrel-progress-bar {
            background: #000;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            transform: v-bind('`translateX(-${100 - calculateProgressPercentage.percentage}%)`');
            border-radius: 20px;
            transition: 0.1s linear;
          }
        }
      }

      .sell-btn {
        background-color: $secondary;
        color: #000 !important;
        height: 20px;
        min-height: 28px;
        font-size: 16px;
        font-weight: 600;
        font-family: $font-secondary;
        text-transform: initial;
        margin-left: 8px;
        padding: 0 12px;
      }
    }

    &-action {
      position: relative;
      width: 100%;
      max-height: 200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(~/assets/img/game/action-bg.svg) no-repeat;
      background-size: 100% 100%;
      background-position: 0 0;
      transition: 0.1s linear;
      opacity: 0;

      &__panel {
        position: relative;
        max-width: 270px;
        width: 270px;
        height: auto;
        width: 60%;
        pointer-events: none;
      }

      &__btn {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 50%;
        display: flex;
        z-index: 3;

        &:not(.active-boosted) svg path:first-of-type {
          animation: unfillAnimation 1s forwards;
        }

        i {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 2;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
            border-radius: 50%;
          }
        }

        svg {
          width: 100%;
          height: 100%;
          display: flex;
        }
      }

      .active-boosted {
        svg {
          path:first-of-type {
            animation: fillAnimation 1s forwards;
          }
        }
      }

      .action-overheated {
        svg {
          path {
            fill: $negative !important;
          }
        }
      }

      .active-animation {
        animation: v-bind(btnAnimation);

        i:before {
          box-shadow: none !important;
        }
      }

      .coin {
        position: absolute;
        color: $secondary;
        font-size: 18px;
        top: -5px;
        left: 50%;
        transform: translatex(-50%);
        animation: flyCoin 1s forwards;
      }
    }
  }
}

@keyframes fillAnimation {
  0% {
    fill: transparent;
  }

  100% {
    fill: v-bind(lightingColor);
  }
}

@keyframes unfillAnimation {
  0% {
    fill: v-bind(lightingColor);
  }

  100% {
    fill: #6f8087;
  }
}

@keyframes flyCoin {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -80px);
    opacity: 0;
  }
}

@keyframes btnAnimation {
  from {
    transform: scale(0.97);
  }
  to {
    transform: scale(1);
  }
}
</style>
