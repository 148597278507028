<script lang="ts" setup>
import type { PropType } from 'vue'
import type { GameTask } from '~/services'

const props = defineProps({
  task: {
    type: Object as PropType<GameTask>,
    required: true,
  },
})

const taskStore = useTasksStore()
const { taskIcon, startTask, state } = useTasks()

const isDisabled = computed(() => {
  const { startTaskId } = taskStore.state
  return startTaskId && startTaskId !== props.task?.id
})

const loading = computed(() => {
  return taskStore.state.startTaskId === props.task?.id && taskStore.state.loading
})

const claimedTasks = computed(() => taskStore.state.claimedTasks)
const alreadyClaimed = computed(() => !!claimedTasks.value.find(t => t.taskId === props.task.id))
</script>

<template>
  <div class="task-default" :class="{ 'claimed-disable': alreadyClaimed }">
    <i v-html="taskIcon(String(task?.icon))" />
    <div class="task-default__info">
      {{ task?.description }}

      <div class="reward">
        +{{ task?.rewardPoints }}
        <i-app-j-points color="#fff" />
      </div>
    </div>

    <task-claim-btn
      :task-id="task?.id"
      :loading="loading"
      :disabled="!!isDisabled"
      :claim-icon="state.claimIcon"
      :claimed="state.claimed"
      @start-task="startTask(task)"
    />
  </div>
</template>
