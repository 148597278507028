<script setup lang="ts">
import { capitalize } from 'lodash-es'
import frensIcon from '~/assets/img/game/frens.svg?raw'
import homeIcon from '~/assets/img/game/home.svg?raw'
import tasksIcon from '~/assets/img/game/tasks.svg?raw'
import tradeIcon from '~/assets/img/game/trade.svg?raw'
import hcMiniIcon from '~/assets/img/logo/hc-mini.svg?raw'

const tabs = [{
  name: 'home',
  icon: homeIcon,
},
{
  name: 'exchange',
  icon: tradeIcon,
},
{
  name: 'tasks',
  icon: tasksIcon,
},
{
  name: 'friends',
  icon: frensIcon,
},
{
  name: 'holders club',
  icon: hcMiniIcon,
}]
const tab = ref(tabs[0]!.name)

const route = useRoute()

const isGame = ref(false)

const taskStore = useTasksStore()

function tabHandler(t: string) {
  tab.value = t
  isGame.value = false
}

const availableTasks = computed(() => taskStore.state.tasks?.length - taskStore.state.claimedTasks.length)

watch(() => route.query?.activeTab, (tab) => {
  if (tab && tabs.find(t => t.name === tab)) {
    tabHandler(String(tab))
  }
})
</script>

<template>
  <app-preloader />
  <div class="pumpjack" :class="{ 'mode-game': isGame }">
    <q-tabs v-model="tab" class="main-tabs">
      <q-tab v-for="t in tabs" :key="t.name" :name="t.name">
        <div v-if="availableTasks && availableTasks > 0 && t.name === 'tasks'" class="tab-indicator">
          {{ availableTasks }}
        </div>
        <i v-html="t.icon" />
        {{ capitalize(t.name) }}
      </q-tab>
    </q-tabs>

    <q-tab-panels v-model="tab" class="main-tabs-panel" animated>
      <q-tab-panel name="home">
        <game-home @game-handler="isGame = !isGame" @tab-handler="tabHandler" />
      </q-tab-panel>

      <q-tab-panel name="exchange">
        <game-exchange />
      </q-tab-panel>

      <q-tab-panel name="friends">
        <game-friends />
      </q-tab-panel>

      <q-tab-panel name="tasks">
        <game-tasks />
      </q-tab-panel>

      <q-tab-panel name="holders club">
        <game-wallet />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>
